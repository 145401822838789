import * as React from "react"
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { IconButton, Toolbar } from '@mui/material';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import * as styles from './Navigation.module.css';
import { useEffect, useState } from 'react';
import { Link } from "gatsby";
import generateKey from '../../util/generateKey';

const Navigation: React.FC<ContentfulNavigation> =(props) => {
  const [activeLink, setActiveLink] = useState('');

  useEffect(() => {
    const {pathname, hash} = location;
    setActiveLink(`${pathname}${hash}`);
  }, [activeLink]);
  
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null)
  };

  const getLinkClasses = (link: string) => {
    const regex = new RegExp(activeLink);
    const classes = `${styles.nonMobileMenu} ${styles.link}`;
    return (`${styles.nonMobileMenu} ${styles.link} ${regex.test(link) ? styles.activeLink : '' }`);
  };

  return (
    <Toolbar component="nav" className={styles.toolbar}>
      <Box component="section" className={styles.box}>
        <img className={styles.logo} src={props.logo.file.url} alt={props.logo.description} />
      </Box>
        { props.links.map(({label, link}: ContentfulLink, index) => <Link key={generateKey(index)} className={`${styles.nonMobileMenu} ${styles.link}`} activeClassName={styles.activeLink}  to={link}>{label}</Link> ) }
      <Button className={styles.contactUs} component={Link} variant="contained" to={props.ctaLink}>{ props.ctaLabel }</Button> 

      <IconButton
        id="menu-button"
        className={styles.mobileMenu} 
        aria-label="Menu Button"
        aria-controls={open ? 'nav-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        ><MenuIcon /></IconButton>

      <Menu
        id="nav-menu"
        className={styles.mobileMenu} 
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        { props.links.map(({label, link}: ContentfulLink, index) => <MenuItem className={styles.link} key={generateKey(index)} component={Link} onClick={handleClose} to={link}>{label}</MenuItem> ) }
        <Divider />
        <MenuItem className={styles.link} component={Link} onClick={handleClose} to={props.ctaLink}>{props.ctaLabel}</MenuItem>
      </Menu>
    </Toolbar>
  )
}

export default Navigation;