import * as React from 'react';
import { Box, CssBaseline } from '@mui/material';
import Navigation from '../Navigation/Navigation';
import Footer from '../Footer/Footer';
import { graphql, StaticQuery } from 'gatsby';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Helmet } from "react-helmet";

export default function Layout({ children, location }) {
  const theme = createTheme({
    typography: {
      fontFamily: [
        'Montserrat',
        'new-science',
        'Open Sans',
        'Roboto',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          root: {
            fontFamily: 'Montserrat',
          }
        }
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontFamily: 'Montserrat',
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          contained: {
            backgroundColor: '#33A09F',
            padding: '1.2rem 3rem',
            borderRadius: '1.88rem',
            fontFamily: 'Montserrat',
            textTransform: 'none',
            fontSize: '16px',
            fontWeight: '600',
            lineHeight: '24px',
          }
        }
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '--TextField-brandBorderColor': '#E0E3E7',
            '--TextField-brandBorderHoverColor': '#B2BAC2',
            '--TextField-brandBorderFocusedColor': '#6F7E8C',
            '& label.Mui-focused': {
              color: 'var(--TextField-brandBorderFocusedColor)',
            },
            paddingBottom: '1.5rem'

          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            'background-color': '#E0ECFD66',
            '&::before, &::after': {
              borderBottom: '2px solid var(--TextField-brandBorderColor)',
            },
            '&:hover:not(.Mui-disabled, .Mui-error):before': {
              borderBottom: '2px solid var(--TextField-brandBorderHoverColor)',
            },
            '&.Mui-focused:after': {
              borderBottom: '2px solid var(--TextField-brandBorderFocusedColor)',
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            '&::before': {
              borderBottom: '2px solid var(--TextField-brandBorderColor)',
            },
            '&:hover:not(.Mui-disabled, .Mui-error):before': {
              borderBottom: '2px solid var(--TextField-brandBorderHoverColor)',
            },
            '&.Mui-focused:after': {
              borderBottom: '2px solid var(--TextField-brandBorderFocusedColor)',
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            position: 'relative',
            left: '-10px',
            color: '#4A566D',
            fontFamily: 'Montserrat',
            fontSize: '14px',
            fontWeight: '600',
            lineHeight: '20px',
            textTransform: 'uppercase',
            paddingBottom: '.5rem'
          }
        }
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            width: '337px',
            height: '48px',
          }
        }
      }
    },
  });

  return (
    <StaticQuery
      query = {graphql`
        query {
          contentfulNavigation {
            logo {
              description
              file {
                url
              }
            }
            links {
              label
              link
            }
            ctaLabel
            ctaLink
          }
          contentfulFooter {
            copywright
          }
        }
      `}
      render={({contentfulNavigation, contentfulFooter}) => (
        <>
          <Helmet>
            <link
            rel="stylesheet"
            href="https://use.typekit.net/nvb5ybk.css"
            />
            <link 
            rel="preconnect"
            href="https://fonts.googleapis.com"
            />
            <link
            rel="preconnect"
            href="https://fonts.gstatic.com"
            crossOrigin="anonymous"
            />
            <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap"
            />
          </Helmet>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Navigation {...contentfulNavigation} />
            <Box component="main" key={location.pathname}>
              {children}
            </Box>
            <Footer {...contentfulFooter} />
          </ThemeProvider>
        </>
      )}
      />
    );
  }