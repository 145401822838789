import { Box, Typography } from '@mui/material';
import * as React from 'react';
import * as styles from './Footer.module.css'
import { Link } from 'gatsby';

const Footer: React.FC<ContentfulFooter> = ({ copywright }) => {
  return (
    <Box component="footer" className={styles.footer}>
      <Typography variant="body1"className={styles.copyright}>{copywright}</Typography>
      <Link to="/privacy-policy" className={styles.copyright}>Privacy</Link>
    </Box>
  )
}

export default Footer;